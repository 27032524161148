<template>
  <div class="container normal" :class="isSafari ? 'safari' : 'other'">
    <div class="title-bar">
      <div class="title">
        Synapsetec Bot
      </div>
      <div v-show="isInReply" class="status">
        正在回复中
      </div>
      <svg
        v-if="false"
        t="1679033205982"
        class="settings icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2757"
        width="200"
        height="200"
        @click="isSettingsDialogShow = true"
      >
        <path
          d="M378.56 946.08a40 40 0 0 1-12.72-2.08 453.04 453.04 0 0 1-154.88-89.44 40 40 0 0 1-10.48-45.44 128 128 0 0 0-101.52-176 40 40 0 0 1-34.16-32 456.4 456.4 0 0 1 0-178.8 40 40 0 0 1 34.16-32 128 128 0 0 0 101.52-176 40 40 0 0 1 10.4-45.44A453.04 453.04 0 0 1 365.84 80a40 40 0 0 1 44.64 13.68 128 128 0 0 0 203.04 0A40 40 0 0 1 658.16 80a453.04 453.04 0 0 1 154.88 89.44 40 40 0 0 1 10.48 45.44 128 128 0 0 0 101.52 176 40 40 0 0 1 34.16 32 456.4 456.4 0 0 1 0 178.8 40 40 0 0 1-34.16 32 128 128 0 0 0-101.52 176 40 40 0 0 1-10.4 45.44A453.04 453.04 0 0 1 658.16 944a40 40 0 0 1-44.64-13.68 128 128 0 0 0-203.04 0 40 40 0 0 1-31.92 15.76z m-112.88-129.12a388.72 388.72 0 0 0 105.36 60.88 192 192 0 0 1 281.92 0 388.64 388.64 0 0 0 105.36-60.88 192 192 0 0 1 140.96-244.16 392 392 0 0 0 0-121.68 192 192 0 0 1-140.96-244.08 388.72 388.72 0 0 0-105.36-60.88 192 192 0 0 1-281.92 0 388.64 388.64 0 0 0-105.36 60.88A192 192 0 0 1 124.72 451.2a392 392 0 0 0 0 121.68 192 192 0 0 1 140.96 244.08z"
          fill="#3E3A39"
          p-id="2758"
        />
        <path
          d="M512 704a192 192 0 0 1 0-384 32 32 0 0 1 0 64 128 128 0 1 0 122.16 89.6 32 32 0 0 1 61.04-19.2A192 192 0 0 1 512 704z"
          fill="#3E3A39"
          p-id="2759"
        />
        <path
          d="M608.010343 384.008971m-22.627417 22.627417a32 32 0 1 0 45.254834-45.254834 32 32 0 1 0-45.254834 45.254834Z"
          fill="#3E3A39"
          p-id="2760"
        />
      </svg>
    </div>
    <div ref="messagesBox" class="messages-box" @click="copyCode">
      <!-- <div class="messages-item user-item">
                  <div class="info">
                      <div class="avatar">
                          <svg t="1679454628918" class="user-avatar icon" viewBox="0 0 1024 1024" version="1.1"
                              xmlns="http://www.w3.org/2000/svg" p-id="5247" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="28px" height="28px">
                              <path
                                  d="M826.816 992H197.184a126.624 126.624 0 0 1-103.84-53.152 126.592 126.592 0 0 1-17.568-115.328l22.528-67.616a193.088 193.088 0 0 1 158.912-129.888C324.192 617.824 422.752 608 512 608c89.216 0 187.808 9.824 254.816 18.016a193.024 193.024 0 0 1 158.88 129.888l22.528 67.616a126.528 126.528 0 0 1-17.6 115.328A126.56 126.56 0 0 1 826.816 992zM512 672c-86.016 0-181.792 9.536-246.976 17.568a128.64 128.64 0 0 0-105.984 86.592l-22.528 67.584c-6.592 19.776-3.392 40.768 8.768 57.664S176.352 928 197.184 928h629.632a63.36 63.36 0 0 0 51.904-26.56c12.192-16.896 15.36-37.92 8.8-57.696l-22.528-67.584a128.64 128.64 0 0 0-105.984-86.592C693.792 681.536 597.984 672 512 672zM512 576c-149.984 0-272-122.016-272-272S362.016 32 512 32c149.984 0 272 122.016 272 272S661.984 576 512 576z m0-480c-114.688 0-208 93.312-208 208S397.312 512 512 512s208-93.312 208-208S626.688 96 512 96z"
                                  p-id="5248" fill="#ffffff"></path>
                              <path
                                  d="M385.28 337.28c-17.376 0-31.616-13.92-32-31.36a158.848 158.848 0 0 1 44.608-114.048 158.976 158.976 0 0 1 112.16-49.12c17.984 0.32 32.288 13.696 32.64 31.36a32 32 0 0 1-31.36 32.64 95.424 95.424 0 0 0-67.296 29.44 95.392 95.392 0 0 0-26.752 68.448 32 32 0 0 1-31.36 32.64h-0.64z"
                                  p-id="5249" fill="#ffffff"></path>
                          </svg>
                      </div>
                      <div class="name">
                          <div class="date">2023-03-22 00:00:00</div>
                          <div class="label">{{userName}}</div>
                      </div>
                  </div>
                  <div class="content" v-html="testContent"></div>
              </div> -->
      <!-- <div class="messages-item bot-item">
        <div class="info">
          <div class="avatar">
            <svg
              class="bot-avatar"
              width="50px"
              height="50px"
              viewBox="0 0 276 276"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Synai</title>
              <g
                id="v0.4（会议"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="synapsetec备份">
                  <rect
                    id="矩形"
                    fill-opacity="0"
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="276"
                    height="276"
                  ></rect>
                  <g
                    id="shape_JZfIU9uh3q"
                    transform="translate(67.430967, 40.682556)"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  >
                    <g id="编组">
                      <path
                        d="M74,70.1 C73,69.9 71.8,69.8 70.5,69.6 C59.6,68.1 48,65.7 48,56.9 C48,49.1 56.8,44 70.4,44 C82.9,44 92.3,50.1 94.8,55.9 C99.7,67.3 111.8,73.4 123,70 C134.5,66.6 140.4,55 136.9,43 C129.4,17.7 102.1,0 70.5,0 C53.4,0 37,5.2 24.5,14.6 C10.1,25.3 2,40.7 2,56.9 C2,67.9 5.6,83.8 22.6,96.5 C24.1,97.7 26.3,97.3 27.5,95.8 C28.7,94.3 28.3,92.1 26.8,90.9 C12.1,79.9 9,66.3 9,56.9 C9,42.9 16.1,29.6 28.6,20.2 C39.9,11.7 54.8,7 70.4,7 C98.9,7 123.5,22.6 130.1,45 C131.3,49 131,53 129.3,56.4 C127.6,59.8 124.7,62.2 120.9,63.3 C113.1,65.6 104.6,61.2 101.1,53.1 C97.8,45.3 86.7,36.9 70.3,36.9 C52.4,36.9 40.9,44.7 40.9,56.8 C40.9,72.5 60.1,75.1 69.4,76.4 C70.6,76.6 71.8,76.7 72.8,76.9 C109.2,82.3 131.8,101.4 131.8,126.6 C131.8,140.5 124.7,153.9 112.2,163.3 C100.9,172 86,176.7 70.4,176.7 C48.1,176.7 28.3,167.5 17.3,152.1 C16.2,150.5 14,150.2 12.4,151.3 C10.8,152.4 10.5,154.6 11.6,156.2 C23.9,173.4 45.8,183.7 70.4,183.7 C87.5,183.7 103.9,178.5 116.4,169.1 C130.6,158.4 138.8,143 138.8,126.8 C138.8,97.8 113.9,76 74,70.1 Z"
                        id="路径"
                      ></path>
                      <rect
                        id="矩形"
                        x="15.8"
                        y="120.5"
                        width="9.6"
                        height="6"
                      ></rect>
                      <path
                        d="M30,126.5 L87.1,126.5 C88.3,129.9 91.6,132.3 95.4,132.3 C100.3,132.3 104.2,128.3 104.2,123.5 C104.2,118.7 100.2,114.7 95.4,114.7 C91.6,114.7 88.3,117.1 87.1,120.5 L30,120.5 L30,126.5 Z M95.4,120.7 C97,120.7 98.2,122 98.2,123.5 C98.2,125 96.9,126.3 95.4,126.3 C93.9,126.3 92.6,125 92.6,123.5 C92.6,122 93.8,120.7 95.4,120.7 Z"
                        id="形状"
                      ></path>
                      <path
                        d="M83.2,115 C88.1,115 92,111 92,106.2 C92,101.4 88,97.4 83.2,97.4 C79.4,97.4 76.1,99.8 74.9,103.2 L46.3,103.2 L46.3,109.2 L74.9,109.2 C76.1,112.6 79.4,115 83.2,115 Z M83.2,103.4 C84.8,103.4 86,104.7 86,106.2 C86,107.7 84.7,109 83.2,109 C81.7,109 80.4,107.7 80.4,106.2 C80.4,104.7 81.6,103.4 83.2,103.4 Z"
                        id="形状"
                      ></path>
                      <rect
                        id="矩形"
                        x="21.6"
                        y="103.2"
                        width="6.4"
                        height="6"
                      ></rect>
                      <rect
                        id="矩形"
                        x="31.8"
                        y="103.2"
                        width="10.7"
                        height="6"
                      ></rect>
                      <rect
                        id="矩形"
                        x="5.77315973e-15"
                        y="138.1"
                        width="7.4"
                        height="6"
                      ></rect>
                      <rect
                        id="矩形"
                        x="11.2"
                        y="138.1"
                        width="13.6"
                        height="6"
                      ></rect>
                      <path
                        d="M28.6,144.1 L74.9,144.1 C76.1,147.5 79.4,149.9 83.2,149.9 C88.1,149.9 92,145.9 92,141.1 C92,136.3 88,132.3 83.2,132.3 C79.4,132.3 76.1,134.7 74.9,138.1 L28.6,138.1 L28.6,144.1 Z M83.2,138.3 C84.8,138.3 86,139.6 86,141.1 C86,142.6 84.7,143.9 83.2,143.9 C81.7,143.9 80.4,142.7 80.4,141.1 C80.4,139.5 81.6,138.3 83.2,138.3 Z"
                        id="形状"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="name">
            <div class="date">2023-03-22 00:00:00</div>
            <div class="label">{{ botName }}</div>
          </div>
        </div>
        <div class="content" v-html="testContent"></div>
      </div> -->
      <div
        v-for="(item, index) in messagesList"
        :key="index"
        class="messages-item"
        :class="item.role + '-item'"
      >
        <div class="info">
          <div class="avatar">
            <template v-if="item.role === 'user'">
              <svg
                t="1679454628918"
                class="user-avatar icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="5247"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="28px"
                height="28px"
              >
                <path
                  d="M826.816 992H197.184a126.624 126.624 0 0 1-103.84-53.152 126.592 126.592 0 0 1-17.568-115.328l22.528-67.616a193.088 193.088 0 0 1 158.912-129.888C324.192 617.824 422.752 608 512 608c89.216 0 187.808 9.824 254.816 18.016a193.024 193.024 0 0 1 158.88 129.888l22.528 67.616a126.528 126.528 0 0 1-17.6 115.328A126.56 126.56 0 0 1 826.816 992zM512 672c-86.016 0-181.792 9.536-246.976 17.568a128.64 128.64 0 0 0-105.984 86.592l-22.528 67.584c-6.592 19.776-3.392 40.768 8.768 57.664S176.352 928 197.184 928h629.632a63.36 63.36 0 0 0 51.904-26.56c12.192-16.896 15.36-37.92 8.8-57.696l-22.528-67.584a128.64 128.64 0 0 0-105.984-86.592C693.792 681.536 597.984 672 512 672zM512 576c-149.984 0-272-122.016-272-272S362.016 32 512 32c149.984 0 272 122.016 272 272S661.984 576 512 576z m0-480c-114.688 0-208 93.312-208 208S397.312 512 512 512s208-93.312 208-208S626.688 96 512 96z"
                  p-id="5248"
                  fill="#ffffff"
                />
                <path
                  d="M385.28 337.28c-17.376 0-31.616-13.92-32-31.36a158.848 158.848 0 0 1 44.608-114.048 158.976 158.976 0 0 1 112.16-49.12c17.984 0.32 32.288 13.696 32.64 31.36a32 32 0 0 1-31.36 32.64 95.424 95.424 0 0 0-67.296 29.44 95.392 95.392 0 0 0-26.752 68.448 32 32 0 0 1-31.36 32.64h-0.64z"
                  p-id="5249"
                  fill="#ffffff"
                />
              </svg>
            </template>
            <template v-else>
              <svg
                class="bot-avatar"
                width="50px"
                height="50px"
                viewBox="0 0 276 276"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>Synai</title>
                <g
                  id="v0.4（会议"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="synapsetec备份">
                    <rect
                      id="矩形"
                      fill-opacity="0"
                      fill="#FFFFFF"
                      x="0"
                      y="0"
                      width="276"
                      height="276"
                    />
                    <g
                      id="shape_JZfIU9uh3q"
                      transform="translate(67.430967, 40.682556)"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    >
                      <g id="编组">
                        <path
                          id="路径"
                          d="M74,70.1 C73,69.9 71.8,69.8 70.5,69.6 C59.6,68.1 48,65.7 48,56.9 C48,49.1 56.8,44 70.4,44 C82.9,44 92.3,50.1 94.8,55.9 C99.7,67.3 111.8,73.4 123,70 C134.5,66.6 140.4,55 136.9,43 C129.4,17.7 102.1,0 70.5,0 C53.4,0 37,5.2 24.5,14.6 C10.1,25.3 2,40.7 2,56.9 C2,67.9 5.6,83.8 22.6,96.5 C24.1,97.7 26.3,97.3 27.5,95.8 C28.7,94.3 28.3,92.1 26.8,90.9 C12.1,79.9 9,66.3 9,56.9 C9,42.9 16.1,29.6 28.6,20.2 C39.9,11.7 54.8,7 70.4,7 C98.9,7 123.5,22.6 130.1,45 C131.3,49 131,53 129.3,56.4 C127.6,59.8 124.7,62.2 120.9,63.3 C113.1,65.6 104.6,61.2 101.1,53.1 C97.8,45.3 86.7,36.9 70.3,36.9 C52.4,36.9 40.9,44.7 40.9,56.8 C40.9,72.5 60.1,75.1 69.4,76.4 C70.6,76.6 71.8,76.7 72.8,76.9 C109.2,82.3 131.8,101.4 131.8,126.6 C131.8,140.5 124.7,153.9 112.2,163.3 C100.9,172 86,176.7 70.4,176.7 C48.1,176.7 28.3,167.5 17.3,152.1 C16.2,150.5 14,150.2 12.4,151.3 C10.8,152.4 10.5,154.6 11.6,156.2 C23.9,173.4 45.8,183.7 70.4,183.7 C87.5,183.7 103.9,178.5 116.4,169.1 C130.6,158.4 138.8,143 138.8,126.8 C138.8,97.8 113.9,76 74,70.1 Z"
                        />
                        <rect
                          id="矩形"
                          x="15.8"
                          y="120.5"
                          width="9.6"
                          height="6"
                        />
                        <path
                          id="形状"
                          d="M30,126.5 L87.1,126.5 C88.3,129.9 91.6,132.3 95.4,132.3 C100.3,132.3 104.2,128.3 104.2,123.5 C104.2,118.7 100.2,114.7 95.4,114.7 C91.6,114.7 88.3,117.1 87.1,120.5 L30,120.5 L30,126.5 Z M95.4,120.7 C97,120.7 98.2,122 98.2,123.5 C98.2,125 96.9,126.3 95.4,126.3 C93.9,126.3 92.6,125 92.6,123.5 C92.6,122 93.8,120.7 95.4,120.7 Z"
                        />
                        <path
                          id="形状"
                          d="M83.2,115 C88.1,115 92,111 92,106.2 C92,101.4 88,97.4 83.2,97.4 C79.4,97.4 76.1,99.8 74.9,103.2 L46.3,103.2 L46.3,109.2 L74.9,109.2 C76.1,112.6 79.4,115 83.2,115 Z M83.2,103.4 C84.8,103.4 86,104.7 86,106.2 C86,107.7 84.7,109 83.2,109 C81.7,109 80.4,107.7 80.4,106.2 C80.4,104.7 81.6,103.4 83.2,103.4 Z"
                        />
                        <rect
                          id="矩形"
                          x="21.6"
                          y="103.2"
                          width="6.4"
                          height="6"
                        />
                        <rect
                          id="矩形"
                          x="31.8"
                          y="103.2"
                          width="10.7"
                          height="6"
                        />
                        <rect
                          id="矩形"
                          x="5.77315973e-15"
                          y="138.1"
                          width="7.4"
                          height="6"
                        />
                        <rect
                          id="矩形"
                          x="11.2"
                          y="138.1"
                          width="13.6"
                          height="6"
                        />
                        <path
                          id="形状"
                          d="M28.6,144.1 L74.9,144.1 C76.1,147.5 79.4,149.9 83.2,149.9 C88.1,149.9 92,145.9 92,141.1 C92,136.3 88,132.3 83.2,132.3 C79.4,132.3 76.1,134.7 74.9,138.1 L28.6,138.1 L28.6,144.1 Z M83.2,138.3 C84.8,138.3 86,139.6 86,141.1 C86,142.6 84.7,143.9 83.2,143.9 C81.7,143.9 80.4,142.7 80.4,141.1 C80.4,139.5 81.6,138.3 83.2,138.3 Z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </template>
          </div>
          <div class="name">
            <div class="date">
              {{ item.date }}
            </div>
            <div class="label">
              {{ item.role === 'user' ? userName : botName }}
            </div>
          </div>
        </div>
        <div class="content" v-html="item.content"></div>
      </div>
    </div>
    <div class="handle-bar">
      <template v-if="isInReply">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </template>
      <template v-else>
        <div
          ref="input"
          class="input"
          contenteditable="true"
          tabindex="0"
        ></div>
        <!-- <input ref="input" type="text" v-model="userMsg" class="input"> -->
        <div class="button" @click="sendMsg">
          发送
        </div>
      </template>
    </div>
    <template v-if="isSettingsDialogShow">
      <div class="settings-dialog">
        <div class="input-box">
          <div class="label">
            用户称呼
          </div>
          <div ref="userNameIpt" class="ipt" contenteditable="true"></div>
        </div>
        <!-- <div class="input-box">
                      <div class="label">AI称呼</div>
                      <div ref="botNameIpt" class="ipt" contenteditable="true"></div>
                  </div> -->
        <div class="btn-box">
          <div class="dia-btn cancel-btn" @click="closeSettingsDialog">
            取消
          </div>
          <div class="dia-btn confirm-btn" @click="updateSettings">
            确认
          </div>
        </div>
      </div>
      <div class="mask-layer"></div>
    </template>
    <div
      v-if="promptShowType !== 'none'"
      class="prompt"
      :class="promptShowType"
    >
      {{ promptContent }}
    </div>
  </div>
</template>

<script>
const Host = 'https://gpt.synapsetec.cn'
export default {
  name: 'Chat',
  data() {
    return {
      testContent: this.$mdCovent
        .makeHtml(
          '这是测试代码：\n```javascript\nconsole.log("Hello World!");\nconsole.log("Hello World!");\n```'
        )
        .replaceAll('<pre><code', '<pre class="code-block"><code'),
      userId: '',
      userMsg: '',
      isInReply: false,
      isSettingsDialogShow: false,
      promptShowType: 'none',
      promptContent: '',
      timmer: null,
      messagesList: [],
      userName: '用户',
      botName: 'Synai'
    }
  },
  computed: {
    isSafari() {
      return /iPhone(\S|\s)*Safari/.test(navigator.userAgent)
    }
  },
  created() {
    let userId = localStorage.getItem('uid')
    if (!userId) {
      userId = Date.now()
      localStorage.setItem('uid', userId)
    }
    this.userId = userId
    // 注册回车键监听发送消息
    document.body.addEventListener('keydown', event => {
      if (event.which === 13) {
        this.sendMsg()
      }
    })
    this.getSettings()
  },
  mounted() {
    this.$nextTick(() => {
      this.$hljs.highlightAll()
    })
  },
  methods: {
    /**
     * 弹出提示窗
     */
    showPrompt(content, type = 'warn') {
      if (!['success', 'warn', 'error'].includes(type)) return
      if (this.timmer) {
        clearTimeout(this.timmer)
        this.promptShowType = 'none'
      }
      this.promptShowType = type
      this.promptContent = content
      this.timmer = setTimeout(() => {
        clearTimeout(this.timmer)
        this.promptShowType = 'none'
      }, 1000)
    },
    getCurrentDateString() {
      return this.$moment().format('YYYY-MM-DD HH:mm:ss')
    },
    inputFocus(innerText = '') {
      this.$nextTick(() => {
        this.$refs['input'].innerText = innerText
        this.$refs['input'].focus()
      })
    },
    sendMsg() {
      this.isInReply = true
      // const userMsg = this.userMsg.trim()
      const userMsg = this.$refs['input'].innerText.trim()
      if (!userMsg) {
        this.showPrompt('内容为空不能发送')
        this.isInReply = false
        this.inputFocus()
        return
      }
      // this.userMsg = ''
      this.$refs['input'].innerText = ''
      this.messagesList.push({
        role: 'user',
        content: userMsg,
        date: this.$moment().format('YYYY-MM-DD HH:mm:ss')
      })
      this.$refs['messagesBox'].scrollTop = this.$refs[
        'messagesBox'
      ].scrollHeight
      const index = this.messagesList.length
      const eventSource = new EventSource(
        `${Host}/demo/chat/${this.userId}?msg=${userMsg}`
      )
      // const eventSource = new EventSourcePolyfill(`http://127.0.0.1:17007/api/chatting/default?msg=${userMsg}`, {
      //     headers: {
      //         Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.jvTaTEQp3C+2n0NTpTuyIA.1abKsPhjY/TY+AZpubBdtA.NTNENDg0MS1COTNDLTRGRTEtODk1Ri1EMTg1MDg5NERFMTAiLCJleHBpcmVzIjoiMjAyNi0wNC0yMFQxNTo1OTo1OS4wMDBaIiwiaXNUcmlhbCI6MCwiaWF0IjoxNjc5NTU5OTAyLCJleHAiOjE2Nzk1NjcxMDJ9.ifywUQGf90dzMkEwEZCVRNOJqpgQL_WWO8s4D8H5e00'
      //     }
      // });
      // const eventSource = new EventSourcePolyfill(`http://127.0.0.1:17007/api/writing/2?prompt=${userMsg}&maxWords=${100}`, {
      //     headers: {
      //         Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.jvTaTEQp3C+2n0NTpTuyIA.1abKsPhjY/TY+AZpubBdtA.NTNENDg0MS1COTNDLTRGRTEtODk1Ri1EMTg1MDg5NERFMTAiLCJleHBpcmVzIjoiMjAyNi0wNC0yMFQxNTo1OTo1OS4wMDBaIiwiaXNUcmlhbCI6MCwiaWF0IjoxNjc5NTU5OTAyLCJleHAiOjE2Nzk1NjcxMDJ9.ifywUQGf90dzMkEwEZCVRNOJqpgQL_WWO8s4D8H5e00'
      //     }
      // });
      let botMsg = ''
      let codeBlock = false
      eventSource.onmessage = e => {
        if (['done', 'fail'].includes(e.data)) {
          eventSource.close()
          this.isInReply = false
          if (e.data === 'fail') {
            // this.userMsg = userMsg
            this.showPrompt('网络错误，请重试', 'error')
            this.inputFocus(userMsg)
            return
          }
          this.inputFocus()
          return
        }

        botMsg += e.data
        codeBlock = botMsg.split('%60%60%60').length % 2 === 0
        if (!this.messagesList[index]) {
          this.messagesList.push({
            role: 'bot',
            content: botMsg,
            date: this.$moment().format('YYYY-MM-DD HH:mm:ss')
          })
        }
        let viewStr = botMsg
        if (codeBlock) {
          viewStr += '%0A%60%60%60'
        }
        // console.log(decodeURIComponent(viewStr))
        this.messagesList[index].content = this.$mdCovent
          .makeHtml(decodeURIComponent(viewStr))
          .replaceAll('<pre><code', '<pre class="code-block"><code')
        this.$nextTick(() => {
          this.$hljs.highlightAll()
        })
        this.$refs['messagesBox'].scrollTop = this.$refs[
          'messagesBox'
        ].scrollHeight
      }

      eventSource.onerror = e => {
        console.log('EventSource failed:', e)
        eventSource.close()
        this.showPrompt('网络错误，请重试', 'error')
        this.isInReply = false
        // this.userMsg = userMsg
        this.inputFocus(userMsg)
        return
      }
    },
    // 获取用户设置
    async getSettings() {
      const res = await this.$axios
        .get(`${Host}/demo/chat/presets/${this.userId}`)
        .then(res => res.data)
        .catch(err => err.message)
      if (res.status === 'OK') {
        this.userName = res.userName
        this.botName = res.botName
      }
    },
    // 更新用户设置
    async updateSettings() {
      const userName = this.$refs['userNameIpt'].innerText.trim()
      // const botName = this.$refs['botNameIpt'].innerText.trim()
      // || botName.length > 5
      if (userName.length > 5) {
        this.showPrompt('名称最长只支持5个字符')
        return
      }
      const res = await this.$axios
        .post(`${Host}/demo/chat/presets/${this.userId}`, {
          userName
          // botName
        })
        .then(res => res.data)
        .catch(err => err.message)
      if (res.status === 'OK') {
        this.showPrompt('更新成功', 'success')
        userName && (this.userName = userName)
        // botName && (this.botName = botName)
        this.isSettingsDialogShow = false
        return
      }
      this.showPrompt('更新失败', 'error')
    },
    closeSettingsDialog() {
      this.$refs['userNameIpt'].innerText = ''
      // this.$refs['botNameIpt'].innerText = ''
      this.isSettingsDialogShow = false
    },
    // 复制代码
    copyCode(event) {
      if (event.target.classList.value !== 'code-block') return
      const content = event.target.childNodes[0].innerText
      // console.log(content)
      // 注册一次性复制事件监听
      document.addEventListener(
        'copy',
        e => {
          e.clipboardData.setData('text/plain', content)
          this.showPrompt('复制成功', 'success')
          e.preventDefault()
        },
        { once: true }
      )
      // 主动触发复制动作
      document.execCommand('copy')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
